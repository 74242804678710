<template>
  <div class="font-base pb-8">
    <router-view />
  </div>

  <AppCopyright class="fixed bottom-0 w-full font-base"/>

  <vue-notification-list
   position="top-right"
   style="z-index: 2147483647;"
  />

  <ModalsContainer />
</template>

<script setup>
  import AppCopyright from '@/components/AppCopyright.vue';
  import { useI18n } from 'vue-i18n';
  import { ModalsContainer } from 'vue-final-modal';

  const { locale } = useI18n();
  locale.value = navigator.language.split( '-' )[ 0 ] || process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr';
</script>

<style lang="scss">
  :root {
    --popper-theme-background-color: #fef9c3;
    --popper-theme-background-color-hover: #fef9c3;
    --popper-theme-text-color: #374151;
    --popper-theme-border-color: #374151;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 10px 8px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(66, 32, 6, 0.25);
  }

  .tooltip-popper {
    @apply max-w-[400px] select-none font-bold text-sm text-center z-50
  }

  .clickable:hover {
    @apply cursor-pointer;
  }

  .collapse-class {
    --easing-dur: calc(var(--vc-auto-duration) * 0.6) cubic-bezier(0.33, 1, 0.68, 1);
    transition: height var(--easing-dur)
  }

  .modal-close-button {
    @apply absolute -top-1 right-0 text-black hover:bg-fourth-hover rounded-full px-2 py-1
  }

  .rp-item-card {
    @apply min-w-[44rem] max-w-[44rem] flex flex-col space-y-2 rounded px-4 py-3;
    @apply shadow shadow-shadowColor bg-white;
    @apply text-secondary-text focus-visible:outline-none;
  }

  .dp-select-btn {
    @apply px-1 pb-0.5 text-white rounded;
    @apply border border-[#1976d2] bg-[#1976d2];
    @apply duration-300 transition
  }

  .dp-cancel-btn {
    @apply px-1 pb-0.5 text-black rounded;
    @apply border border-2 border-gray-300 hover:border-[#1976d2] bg-white;
    @apply duration-300 transition
  }

  .popup {
    @apply p-3 bg-white rounded-lg space-y-2 text-secondary-text ;
    @apply w-full max-h-screen overflow-auto ;
  }

  .popup-w-sm {
    @apply max-w-screen-sm ;
  }

  .popup-w-md {
    @apply max-w-screen-md ;
  }

  .popup-w-lg {
    @apply max-w-screen-lg ;
  }

  .popup-w-xl {
    @apply max-w-screen-xl ;
  }

  .popup-w-2xl {
    @apply max-w-screen-2xl ;
  }

  input[type="radio"] {
    @apply accent-accentColor;
    @apply aspect-square w-5;
    @apply cursor-pointer;
  }

  input[type="checkbox"] {
    @apply accent-accentColor;
    @apply aspect-square w-6 rounded;
  }

  .bg-themes-1 { background-color: #f97316; } /* Orange-500 */
  .bg-themes-2 { background-color: #22c55e; } /* Green-500 */
  .bg-themes-3 { background-color: #3b82f6; } /* Blue-500 */
  .bg-themes-4 { background-color: #a855f7; } /* Purple-500 */
  .bg-themes-5 { background-color: #f43f5e; } /* Rose-500 */
  .bg-themes-6 { background-color: #ef4444; } /* Red-500 */
  .bg-themes-7 { background-color: #71717a; } /* Zinc-500 */
  .bg-themes-8 { background-color: #fcd34d; } /* Amber-300 */
  .bg-themes-9 { background-color: #6ee7b7; } /* Emerald-300 */
  .bg-themes-10 { background-color: #a5b4fc; } /* Indigo-300 */
  .bg-themes-11 { background-color: #f0abfc; } /* Fushia-300 */
  .bg-themes-12 { background-color: #f9a8d4; } /* Pink-300 */
  .bg-themes-13 { background-color: #1e293b; } /* Slate-800 */
  .bg-themes-14 { background-color: #14532d; } /* Green-900 */
  .bg-themes-15 { background-color: #1e3a8a; } /* Blue-900 */
  .bg-themes-16 { background-color: #581c87; } /* Purple-900 */
  .bg-themes-17 { background-color: #881337; } /* Rose-900 */
  .bg-themes-18 { background-color: #a1a1aa; } /* Zinc-400 */
  .bg-themes-19 { background-color: #fef08a; } /* Yellow-200 */
  .bg-themes-20 { background-color: #bfdbfe; } /* Blue-200 */

  .--dp-small {
    --dp-input-padding: 6px;
    --dp-font-size: 12px;
    --dp-input-icon-padding: 26px;
  }
</style>
