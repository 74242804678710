import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { useStore } from '@/store/store';
import i18n from './i18n';
import VueNotificationList from '@dafcoe/vue-notification';
import VueCryptojs from 'vue-cryptojs';
import { createVfm } from 'vue-final-modal';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import Popper from 'vue3-popper';

import './assets/tailwind.css';
import 'vue3-easy-data-table/dist/style.css';
import '@dafcoe/vue-notification/dist/vue-notification.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-final-modal/style.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faRightToBracket, faGear, faHome,
         faCheck, faXmark, faCalendar, faClock, faTrashCan, faPlus, faAnglesLeft, faAnglesRight,
         faMagnifyingGlassPlus, faMagnifyingGlassMinus, faTable, faCircleInfo, faGears, faShieldHalved,
         faLightbulb, faChartLine, faArrowRight, faHourglassHalf, faCircleCheck, faCircleHalfStroke,
         faChevronRight, faUpload, faPen, faEye, faBackward, faSort,
         faSortUp, faSortDown, faClipboardList, faUsers, faBuilding, faIdBadge, faCircleExclamation,
         faTriangleExclamation, faGripVertical, faRotateRight, faTableCells, faChartColumn,
         faBoxArchive, faLock, faUser
       }
       from '@fortawesome/free-solid-svg-icons';

library.add( [ faRightToBracket, faGear, faHome,
              faCheck, faXmark, faCalendar, faClock, faTrashCan, faPlus, faAnglesLeft, faAnglesRight,
              faMagnifyingGlassPlus, faMagnifyingGlassMinus, faTable, faCircleInfo, faGears, faShieldHalved,
              faLightbulb, faChartLine, faArrowRight, faHourglassHalf, faCircleCheck, faCircleHalfStroke,
              faChevronRight, faUpload, faPen, faEye, faBackward, faSort,
              faSortUp, faSortDown, faClipboardList, faUsers, faBuilding, faIdBadge, faCircleExclamation,
              faTriangleExclamation, faGripVertical, faRotateRight, faTableCells, faChartColumn,
              faBoxArchive, faLock, faUser
            ] );

const pinia = createPinia();
pinia.use( piniaPersist );

const vfm = createVfm();

createApp( App )
  .use( i18n )
  .use( router )
  .use( pinia )
  .use( VueNotificationList )
  .use( VueCryptojs )
  .use( vfm )
  .component( 'Popper', Popper )
  .component( 'font-awesome-icon', FontAwesomeIcon )
  .mount( '#app' );

useStore();